
import * as vercelRuntime$b8uWPRx1qM from '/vercel/path0/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "avatarSm": 40,
    "thumbnail2xs": 60,
    "thumbnailXs": 100,
    "thumbnailSm": 150,
    "thumbnailMd": 200,
    "thumbnailLg": 300,
    "thumbnailXl": 600,
    "imgMd": 800,
    "imgLg": 1200,
    "imgXl": 1600,
    "img2xl": 2400
  },
  "presets": {},
  "provider": "vercel",
  "domains": [
    "assets.daisyanddaniel.wedding"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$b8uWPRx1qM, defaults: {} }
}
        